/* eslint-disable no-unused-vars */
import * as React from "react";

import {
    Datagrid,
    DeleteButton,
    List, Pagination, ReferenceField,
    TextField
} from "react-admin";

export const AffiliateList = (props) => (
    <List {...props} perPage={100} pagination={<Pagination rowsPerPageOptions={[100, 150, 200, 250]} />} sort={{ field: 'id', order: 'DESC' }}>
        <Datagrid rowClick="show">
            <TextField source="id" />
            <TextField source="affiliateCode" />
            <ReferenceField source="userId" reference="v1/admin/users" link="show"><TextField source="email" /></ReferenceField>
            <TextField source="referralCommissionPercent" />
            <TextField source="vaultReferralCommissionPercent" />
            <DeleteButton />
        </Datagrid>
    </List>
);
