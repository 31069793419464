import { TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from '@material-ui/icons/Delete';
import axios from "axios";
import { useSnackbar } from 'notistack';
import { useEffect, useState } from "react";
import { useDataProvider } from "react-admin";
import { BASE_URL } from "../../../../config/env";

export const BulkOrderList = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const [field, setField] = useState([{ symbol: "", "quantity": 0, "total": 0 }]);
    const dataProvider = useDataProvider();
    const [balanceDetails, setBalanceDetails] = useState();
    const getBalances = () => {
        axios.get(BASE_URL + "/v1/user/wallet", { headers: { "Authorization": "Bearer " + localStorage.getItem("token") } })
            .then((response) => {

                setBalanceDetails(response);

            })
            .catch((error) => {

            });

    };

    useEffect(() => {
        const orders = JSON.parse(localStorage.getItem('orders'));
        if (orders && orders.length > 0) {
            setField(orders);
        } else {
            setField([{ symbol: "", "quantity": 0, "total": 0 }]);
        }
        getBalances();
    }, []);
    const handleChangeInput = (index, event) => {
        const values = [...field];
        values[index][event.target.name] = event.target.value;
        setField(values);
        localStorage.setItem('orders', JSON.stringify(values));
        // console.log(index, event.target.value);
    };

    const handleBid = (e) => {
        console.log("BID: ", field);
        field.forEach((order) => {
            console.log(order.symbol, order.quantity, order.total);
            order['total'] = parseFloat(order.total);
            order['quantity'] = parseFloat(order['quantity']);
            if (order.symbol === "" || (order.quantity === 0 && order.total === 0)) {
                return;
            }
            order['action'] = "BID"
            order['orderType'] = "MARKET"

            if (order.total === 0) {
                delete order.total;
            }
            if (order.quantity === 0) {
                delete order.quantity;
            }
            console.log(order);
            axios.post(BASE_URL + "/v1/orders", order, { headers: { "Authorization": "Bearer " + localStorage.getItem("token") } })
                .then((response) => {
                    enqueueSnackbar(`${order.symbol} BUY Order Successful.`, { "variant": "success" });
                })
                .catch((error) => {
                    if (error.response.data?.message?.includes("Full authentication")) {
                        enqueueSnackbar("Please login to place orders", { "variant": "error" });
                        return;
                    }
                    if (error.response.data.message?.includes("Insufficient")) {
                        enqueueSnackbar(`${order.symbol} BUY Order Failed.  Insufficient Balance on Zelta`, { "variant": "error" });
                        return;
                    }

                    enqueueSnackbar(`${order.symbol} BUY Order Failed.  ${error.response.data.message}`, { "variant": "error" });
                });
        });
        e.preventDefault();

    };

    const handleAsk = (e) => {
        console.log("ASK: ", field);
        field.forEach((order) => {
            order['total'] = parseFloat(order.total);
            order['quantity'] = parseFloat(order['quantity']);
            if (order.symbol === "" || (order.quantity === 0 && order.total === 0)) {
                return;
            }
            order['action'] = "ASK"
            order['orderType'] = "MARKET"
            if (order.total === 0) {
                delete order.total;
            }
            if (order.quantity === 0) {
                delete order.quantity;
            }

            axios.post(BASE_URL + "/v1/orders", order, { headers: { "Authorization": "Bearer " + localStorage.getItem("token") } })
                .then((response) => {

                    enqueueSnackbar(`${order.symbol} SELL Order Successful`, { "variant": "success" });

                })
                .catch((error) => {
                    if (error.response.data.message?.includes("Insufficient")) {
                        enqueueSnackbar(`${order.symbol} SELL Order Failed.  Insufficient Balance on Zelta`, { "variant": "error" });
                        return;
                    }
                    enqueueSnackbar(`${order.symbol} BUY Order Failed.  ${error.response.data.message}`, { "variant": "error" });
                });
        });
        e.preventDefault();
        console.log("Input: ", field);
    };

    const addField = () => {
        setField([...field, { symbol: "", "quantity": 0, "total": 0 }]);
        localStorage.setItem('orders', JSON.stringify(field));
    };

    const removeField = (index) => {
        const values = [...field];
        values.splice(index, 1);
        setField(values);
        localStorage.setItem('orders', JSON.stringify(values));
    };

    return (
        <>
            <Container style={{ display: "flex", justifyContent: "space-between" }}>

                <form  >
                    {field.map((field, index) => (
                        <div key={index} style={{ margin: "10px" }}>
                            <TextField
                                name="symbol"
                                type="text"
                                label="Symbol"
                                variant="outlined"
                                value={field.symbol}
                                onChange={(event) => handleChangeInput(index, event)}
                            /> {" " + " "}
                            <TextField
                                name="quantity"
                                type="text"
                                label="quantity"
                                variant="outlined"
                                value={field.quantity}
                                onChange={(event) => handleChangeInput(index, event)}
                            />
                            {" " + "or" + " "}
                            <TextField
                                name="total"
                                type="text"
                                label="total in Quote"
                                variant="outlined"
                                value={field.total}
                                onChange={(event) => handleChangeInput(index, event)}
                            />
                            <IconButton onClick={() => removeField(index)}>
                                <DeleteIcon />
                            </IconButton>

                        </div>
                    ))} <IconButton onClick={addField}>
                        <AddIcon />
                    </IconButton><hr />

                    <div style={{ margin: "0px auto", textAlign: "center" }} >
                        <Button style={{ backgroundColor: "green", margin: 20 }} variant="contained" onClick={handleBid}>BUY</Button>
                        <Button style={{ backgroundColor: "#FF0000" }} variant="contained" onClick={handleAsk}>SELL</Button>
                    </div>
                </form>


            </Container>

            {/* <div style={{ width: "80%", margin: "0px auto", }}>
                <h2>Balances</h2><br />
                <BalanceShow balanceDetails={balanceDetails} />
            </div> */}
        </>
    );
}