/* eslint-disable no-unused-vars */
import { Button } from "@material-ui/core";
import * as React from "react";
import { useState } from "react";
import {
    Datagrid,
    DateField,
    FilterLiveSearch,
    List, Pagination, ReferenceField,
    TextField, useDataProvider,
    useRefresh
} from "react-admin";
import { StatusFilter } from '../../filters/taskReviewFilter';

const TaskReportFilter = (props) => (
    <div>
        <FilterLiveSearch source="searchId" label="Search" />
        <br />
        <StatusFilter source="status" />
        <br />
        <Button
            id="clearButton"
            variant="outlined"
            onClick={() => props.setFilters({})}
        >
            Clear fields
        </Button>
        <br /><br />
    </div>
);

const PostPagination = (props) => (
    <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const TaskReportList = (props) => {
    const dataProvider = useDataProvider();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const refresh = useRefresh();

    return (
        <div>
            <List
                bulkActionButtons={false}
                {...props}
                filters={<TaskReportFilter />}
                pagination={<PostPagination />}
                perPage={100}
                filterDefaultValues={{ status: "AWAIT_ADMIN_ACTION" }}
                sort={{ field: "createdAt", order: "DESC" }}
                actions={null}
            >

                <Datagrid rowClick="show">
                    <TextField source="id" />
                    <ReferenceField source="userId" reference="v1/admin/users" link="show"><TextField source="email" /></ReferenceField>
                    <TextField source="taskType" />
                    <ReferenceField source="taskId" reference="v1/admin/tier/tasks" label="Task Name" link="show"><TextField source="taskName" /></ReferenceField>
                    <TextField source="status" />
                    <TextField source="userInputs[0].value" label={"User Input"} />
                    <DateField source="createdAt" showTime />
                    <DateField source="updatedAt" showTime />


                </Datagrid>
            </List>
        </div>
    );
};

export default TaskReportList;
