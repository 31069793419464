import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
    Datagrid,
    DateField,
    ExportButton,
    FilterLiveSearch,
    FunctionField,
    List,
    TextField
} from "react-admin";
import { ColoredChipField } from "../../../helpers/colorChip";
import useResizer from "../../../utils/useResizer";
import {
    ActionFilter,
    MarketFilter,
    StatusFilter,
} from "../../filters/transactionFilter";

const FilterSidebar = (props) => {
    const useStyles = makeStyles((theme) => ({
        root: {
            "& .MuiFormLabel-root": {
                fontSize: "12px",
            },
            "& .MuiToolbar-root": {
                width: "100%",
            },
            "& .MuiPaper-root": {
                width: "350px",
                overflow: "auto",
            },
        },
    }));
    const classes = useStyles();
    const isSmall = useMediaQuery("(max-width:768px)");
    return (
        <div>
            <div className=" min-w-full mt-2 md:mt-0  md:min-w-max block">
                <div className="flex m md:w-max md:block items-center flex-wrap">
                    <FilterLiveSearch className={`w-[45%] md:w-auto ${isSmall && classes.root}`} source="orderId" label="Search by ID" />{" "}
                    &nbsp;&nbsp;&nbsp;
                    <FilterLiveSearch className={`w-[45%] md:w-auto ${isSmall && classes.root}`} source="userEmail" label="Search by User Email" />{" "}
                    <FilterLiveSearch className={`w-[45%] md:w-auto ${isSmall && classes.root}`} source="symbol" label="Search by market" /> <br />
                </div>
                <StatusFilter source="status" />
                <div className="flex flex-col w-full md:flex-row md:items-center gap-x-20">
                    <MarketFilter source="type" />
                    <ActionFilter source="action" />
                    <Button
                        className="w-max"
                        id="clearButton"
                        variant="outlined"
                        onClick={() => props.setFilters({})}
                    >
                        Clear fields
                    </Button>
                </div>
            </div>
        </div>
    )
}

export const OrderList = (props) => {
    const { width, height } = useResizer()
    const useStyles = makeStyles((theme) => ({
        root: {
            "& .MuiFormLabel-root": {
                fontSize: "12px",
            },
            "& .MuiToolbar-root": {
                width: "100%",
            },
            "& .MuiPaper-root": {
                width: "350px",
                overflow: "auto",
                height: `${height - 320}px`,
                [theme.breakpoints.down('768')]: {
                    height: 'auto'
                },
                position: 'static'
            },
            "& .MuiTablePagination-root": {
                position: "absolute",
                zIndex: 10,
                bottom: 0,
                [theme.breakpoints.down('768')]: {
                    bottom: '-50px'
                },
                right: 0,
                left: 0,
                display: 'flex'
            }
        },
        head: {
            "& .MuiTableCell-head": {
                fontWeight: 700
            }
        }
    }));
    const classes = useStyles();
    return (
        <List
            className={`${classes.root} ${classes.head} overflow-x-hidden`}
            {...props}
            sort={{ field: "createdAt", order: "DESC" }}
            filters={<FilterSidebar />}
            actions={<ExportButton className="hidden md:block" maxResults={25000} />}
            bulkActionButtons={false}
        >
            <Datagrid
                rowClick="show"
            >
                {/* <TextField source="id" /> */}
                <DateField source="updatedAt" showTime />
                <TextField source="orderId" />
                {/* <NumberField source="uid" /> */}
                {/* <TextField source="userDetails.email" /> */}
                <FunctionField
                    onClick={(event) => {
                        event.stopPropagation();
                    }}
                    label="Email"
                    render={(record) => {
                        console.log(record);
                        return (
                            <a style={{ color: "#000" }} className="redirect" href={`/#/v1/admin/users/${record.userId}/show`}>
                                {record?.userDetails?.email || ""}
                            </a>
                        );
                    }}
                />
                {/* <TextField source="userId" /> */}
                <TextField source="symbol" />
                <ColoredChipField source="action" />
                {/* <TextField source="clientOrderId" /> */}
                <TextField source="avgPrice" />
                <TextField source="origPrice" />
                <TextField source="origQty" />
                <TextField label="total" source="executedTotal" />

                <TextField source="execQty" />

                <FunctionField
                    label="fee"
                    render={(record) => {
                        return `${record.fee.toFixed(8)} ${record.action === "BID"
                            ? record.baseCurrencyName
                            : record.quoteCurrencyName
                            }`;
                    }}
                />
                <ColoredChipField source="status" />
                <ColoredChipField source="externalOrderStatus" />
                <TextField source="type" />
                {/* <TextField source="action" /> */}
                {/* <NumberField source="stopPrice" />
            <NumberField source="stopLimitPrice" />
            <TextField source="icebergQty" /> */}
                {/* <DateField source="createdAt" showTime /> */}

                {/* <NumberField source="orderRequest.price" /> */}
                {/* <TextField source="tradeEvents" /> */}
                {/* <NumberField source="baseScale" />
      <NumberField source="quoteScale" />
      <NumberField source="lotSize" />
      <NumberField source="stepSize" /> */}
            </Datagrid>
        </List>
    )
};
