/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable no-unused-vars */
import { Container } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Tab from "@material-ui/core/Tab";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import React, { useEffect } from "react";
import roundOff from "../../../utils/roundOff";

import moment from "moment-timezone";
import { useQuery } from "react-admin";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3} paddingLeft={0} paddingRight={0}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const getDiscrepancy = (key, row, props) => {
    const total =
        row.tde +
        row.tdi +
        row.tb +
        row.ti +
        row.tc -
        row.twe -
        row.twi -
        row.ts -
        row.tf;
    if (props.balances[key]) {
        return Math.round(
            props.balances[key]?.totalBalance +
            props.balances[key]?.totalInOrderBalance +
            props.balances[key]?.totalVaultBalance -
            total,
            8
        );
    } else {
        return total;
    }
};

function getTable(index, key, props, row) {
    return row.tde +
        row.tdi +
        row.tb +
        row.ti +
        row.tc +
        row.twe +
        row.twi +
        row.ts +
        row.tf !=
        0 ? (
        <TableRow key={index}>
            <TableCell> {key}</TableCell>
            <TableCell style={{ color: "green" }}>
                {" "}
                {roundOff(
                    props.balances[key]?.totalBalance
                        ? props.balances[key]?.totalBalance +
                        props.balances[key]?.totalInOrderBalance +
                        props.balances[key]?.totalVaultBalance
                        : 0
                )}
            </TableCell>
            <TableCell style={{ color: "green" }}>
                {" "}
                {roundOff(
                    props.balances[key]?.totalBalance
                        ? props.balances[key]?.totalBalance
                        : 0
                )}
            </TableCell>
            <TableCell style={{ color: "green" }}>
                {" "}
                {roundOff(
                    props.balances[key]?.totalInOrderBalance
                        ? props.balances[key]?.totalInOrderBalance
                        : 0
                )}
            </TableCell>
            <TableCell style={{ color: "green" }}> {roundOff(row.tde)}</TableCell>
            <TableCell style={{ color: "green" }}> {roundOff(row.tdi)}</TableCell>
            <TableCell style={{ color: "red" }}> {roundOff(row.twe)}</TableCell>
            <TableCell style={{ color: "red" }}> {roundOff(row.twi)}</TableCell>
            <TableCell style={{ color: "green" }}> {roundOff(row.tb)}</TableCell>
            <TableCell style={{ color: "red" }}> {roundOff(row.ts)}</TableCell>
            <TableCell style={{ color: "green" }}> {roundOff(row.tvl)}</TableCell>
            <TableCell style={{ color: "red" }}> {roundOff(row.ti)}</TableCell>
            <TableCell style={{ color: "red" }}> {roundOff(row.tc)}</TableCell>
            <TableCell style={{ color: "red" }}> {roundOff(row.tf)}</TableCell>
            <TableCell> {getDiscrepancy(key, row, props)}</TableCell>
        </TableRow>
    ) : null;
}

const TableHeaders = (props) => {
    return (
        <TableRow>
            <TableCell> Currency</TableCell>
            <TableCell> Total Balance</TableCell>
            <TableCell> Free </TableCell>
            <TableCell> In Order</TableCell>
            <TableCell> Total Deposit External</TableCell>
            <TableCell> Total Deposit Internal</TableCell>
            <TableCell> Total Withdraw External</TableCell>
            <TableCell> Total Withdraw Internal</TableCell>
            <TableCell> Total Buy</TableCell>
            <TableCell> Total Sell</TableCell>
            <TableCell> Total Vault Locked</TableCell>
            <TableCell> Total Vault Interest</TableCell>
            <TableCell> Total Commission</TableCell>
            <TableCell> Total Fee</TableCell>
            <TableCell> Total Discrepancy</TableCell>
        </TableRow>
    );
};
const DataRows = (props) => {
    var search = props.search;
    return search
        ? props.audit
            .filter((x) => x[0]?.includes(search.toUpperCase()))
            .map(([key, row], index) => getTable(index, key, props, row))
        : props.audit.map(([key, row], index) => getTable(index, key, props, row));
};

export const Metrics = (props) => {
    const [auditAll, setAuditAll] = React.useState([]);
    const [auditToday, setAuditToday] = React.useState([]);
    const [auditBalancesState, setAuditBalancesState] = React.useState({});
    const [value, setValue] = React.useState(0);
    const [timestamp, setTimestamp] = React.useState();

    const [search, setSearch] = React.useState();
    const audit = useQuery({
        type: "GET_ITEM",
        resource: timestamp ? `v1/admin/audit?timestamp=${timestamp}` : "v1/admin/audit",
        payload: {
            id: "",
        },
    });
    const auditBalances = useQuery({
        type: "GET_LIST",

        resource: "v1/admin/totalBalances",
        payload: {
            pagination: { page: 1, perPage: 100 },
            sort: { field: "updatedAt", order: "DESC" },
            filter: {
                searchUserId: props.id,
                searchId: props.searchId,
                side: props.side,
                marketType: props.type,
                status: props.status,
            },
        },
    });

    function a11yProps(index) {
        return {
            id: `scrollable-auto-tab-${index}`,
            "aria-controls": `scrollable-auto-tabpanel-${index}`,
        };
    }

    useEffect(() => {
        if (audit?.data) {
            setAuditAll(Object.entries(audit?.data?.ALL));
            setAuditToday(Object.entries(audit?.data?.TODAY));
        } else {
            setAuditAll([]);
            setAuditToday([]);
        }
    }, [audit]);

    useEffect(() => {
        if (auditBalances?.data) {
            var map = {};
            auditBalances?.data?.forEach((e) => {
                map[e.currencyShortName] = e;
            });
            setAuditBalancesState(map);
            console.log(map);
        }
    }, [auditBalances]);

    const handleChange = (e) => {
        setSearch(e.target.value);
    };

    const handleChangeNew = (event, newValue) => {
        setValue(newValue);
    };
    const todayDate = new Date();
    const month =
        todayDate.getMonth() + 1 < 10
            ? `0${todayDate.getMonth() + 1}`
            : todayDate.getMonth() + 1;
    const dateFormat = `${todayDate.getFullYear()}-${month}-${todayDate.getDate()}`;
    console.log(audit, timestamp);
    return (
        <>
            <Container style={{ paddingLeft: 0, paddingRight: 0 }}>
                <AppBar elevation={0} position="relative" color="#fafafa">
                    <Tabs
                        value={value}
                        onChange={handleChangeNew}
                        indicatorColor="primary"
                        textColor="primary"
                        wrapped
                    >
                        <Tab label="All" {...a11yProps(0)} />
                        <Tab
                            onClick={() => {
                                setTimestamp();
                            }}
                            label="Today"
                            {...a11yProps(1)}
                        />
                    </Tabs>
                </AppBar>
                {value == 0 ? (
                    <div className="my-4 ml-3">
                        <p>Select Date</p>
                        <input
                            type="date"
                            max={dateFormat}
                            className="border border-gray-400 rounded-md p-1"
                            onChange={(e) => {
                                if (dateFormat == e.target.value) {
                                    setTimestamp();
                                } else {
                                    setTimestamp(new Date(e.target.value).getTime());
                                }
                            }}
                        />
                    </div>
                ) : (
                    <div></div>
                )}
                <TabPanel value={value} index={0}>
                    <TableContainer component={Paper}>
                        <input
                            style={{
                                width: "150px",
                                height: "30px",
                                marginLeft: 4,
                                marginTop: 4,
                            }}
                            type="text"
                            placeholder="search"
                            onChange={handleChange}
                        />
                        <div className="flex items-center gap-x-1 mt-1 pl-1">
                            Date:
                            <p>
                                {audit?.error
                                    ? moment(new Date()).format("D-MM-YYYY")
                                    : timestamp
                                        ? moment(new Date(timestamp)).format("D-MM-YYYY")
                                        : moment(new Date()).format("D-MM-YYYY")}
                            </p></div>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableHeaders />
                            </TableHead>
                            <TableBody>
                                <DataRows
                                    search={search}
                                    audit={auditAll}
                                    balances={auditBalancesState}
                                />
                            </TableBody>
                        </Table>
                    </TableContainer>
                </TabPanel>

                <TabPanel value={value} index={1}>
                    <TableContainer style={{ paddingLeft: 0 }} component={Paper}>
                        <input type="text" placeholder="search" onChange={handleChange} />
                        <Table
                            sx={{ minWidth: 650 }}
                            style={{ paddingLeft: 0 }}
                            aria-label="simple table"
                        >
                            <TableHead>
                                <TableHeaders />
                            </TableHead>
                            <TableBody>
                                <DataRows
                                    search={search}
                                    audit={auditToday}
                                    balances={auditBalancesState}
                                />
                            </TableBody>
                        </Table>
                    </TableContainer>
                </TabPanel>
            </Container>
        </>
    );
};

export default Metrics;
